.project-card{
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0px;
    padding: 8px 0px;
}

.project-photo{
    width: 40%;
}

.project-info{
    margin-right: 24px;
}

.project-info p{
    display: flex;
    flex-wrap: wrap;
    max-width: 80%;
}
.project-title{
    font-size: 20px;
    font-weight: 600;
}
.project-tags{
    display: flex;
    max-width: 60%;
    flex-wrap: wrap;
}
.tags{
    padding: 4px 8px;
    background-color: aquamarine;
    border-radius: 4px;
    margin-right: 6px;
    margin-bottom: 4px;
}

.project-links a{
    text-decoration: none;
    color: black;
}

.project-links{
    margin-top: 6px;
    display: flex;
}

.link-button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aquamarine;
    width: max-content;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 6px;
}

.link-button i{
    display: flex;
    align-items: center;
    mask-repeat: 4px;
}

@media only screen and (max-width: 720px) {
    .project-photo{
        width: 70%;
    }
    .project-card{
        flex-direction: column-reverse;
    }
    .project-info{
        margin-left: 0px;
        margin-top: 12px;
    }
    .project-tags{
        max-width: 100%;
    }
}